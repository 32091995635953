.mainWrapper {
    height: 100%;
}

.loaderWrapper {
    display: flex; /* Antd styles not here at this stage */
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}