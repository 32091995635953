.heading {
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
}

.headingWrapper {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.addSceneIcon {
	display: inline-block;
}

.addSceneButton {
	display: flex;
	justify-content: center;
	align-items: center;
}

.card ul {
	border-radius: 0px 0px 12px 12px;
}

.listGrid {
	margin-top: 40px;
}

.emptyWrapper {
	margin-top: 150px;
	margin-bottom: 150px;
}

.containerStudioScenes {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.emptyHint {
	margin-top: 60px;
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	color: #333333;
}

.deleteButtonsWrapper {
	width: 100%;
	display: flex;
	margin-top: 40px;
	justify-content: flex-end;
}

.deleteButton,
.cancelButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 34px;
	gap: 12px;
	height: 40px;
	border-radius: 12px;
	border: none;
}

.deleteButton {
	background: #f04438;
}

.deleteButton:hover,
.deleteButton:focus {
	background: #f4675d;
}

.deleteButton:active {
	background: #be2c21;
}

.deleteButton:disabled,
.deleteButton:disabled:hover {
	background-color: #ababab;
}

.cancelButton {
	margin-left: 20px;
	background: #5c369a;
	border-radius: 12px;
}

.cancelButton:hover,
.cancelButton:focus {
	opacity: 0.8;
	background: #5c369a;
}

.cancelButton:active {
	background: #3f2569;
}

.input {
	height: 40px;
}

.alertInputWrapper {
	margin-top: 20px;
}

.fontBold {
	font-weight: bold;
}

.editTxtBtn {
	position: absolute;
	right: 24px;
}

.loadingRoot {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadingText {
	margin-top: 10px;
	text-align: center;
}

.spin {
	width: 100px;

	object-fit: cover;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 4s linear infinite;
}

.sortAndSearch {
	display: flex;
	gap: 12px;
	align-items: center;
	height: 54px;	
	margin-top: 36px;
}

.sortScene {
	height: 54px;
	width: 150px;
}

.sceneSearch {
	height: 54px;
}

.addSceneCard {	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px dashed #d9d9d9;
	border-radius: 8px;
	height: 280px;
	width: 350px;
	cursor: pointer;
	padding: 24px;
	gap: 10px;
}

.plusWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 2px 2px 2px 2px #0000001A;
	height: 100%;
}

.createSceneText {
	color: #222222;
}

.addSceneCardPlus {
	font-size: 46px;
	color: #222222;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.percentage {
	color: white;
	font-weight: bold;
}

.active {
	visibility: visible;
}
.hidden {
	visibility: hidden;
}

.containerGeneral {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 100%;
	background: transparent;
	position: absolute;
	height: calc(100vh - 65px);
}
.containerGeneral > img,
.containerGeneral > div,
.containerGeneral > p {
	z-index: 10;
}

.containerLoader {
	position: relative;
	width: 125px;
	height: 50px;
}
.containerLoader .progress {
	height: 22px;
}

@media only screen and (max-width: 600px) {
	.containerStudioScenes {
		align-items: center;
		justify-content: space-evenly;
	}
}
